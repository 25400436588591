//site
$primary-h: 46;
$primary-s: 69;
$primary-b: 95;

$unione: #007a52;

$primary: #f2cb4c;
$primary-text: #000;
$secondary: #30373d;
$tertiary: #5a6771;
$tertiary-text: #fff;

$header-slim-bg-color: $secondary;
$header-center-bg-color: #fff;

//$headings-color: $primary;
//$semibold: 600;

$link-color: $unione;
